<template>
  <div>
      <v-main class="pa-0" style="background-color:#fff; min-height:100vh">
        <v-container fluid :style="mobilePhone?{width:'100%'}:{width:'80%'}" class="justify-content-center">
        <v-row class="justify-content-center">
            <v-col cols="12" align="left" :class=" mobilePhone ? 'mt-10':''">
                <h1 class="headline font-weight-light" :style="mobilePhone?{textAlign:'center'}:''">
                 <span style="color:#39bef9">KOPERASI</span> <span style="color:#083049">BINA UMATI FADHAL UMAR</span>
                </h1>
                <v-divider />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align="left">
                <p class="title">Latar Belakang</p>

                <p>
                    Alhamdulillah dengan karunia Allah SWT. Koperasi di lingkungan Jemaat
                    sudah berdiri dan telah memiliki Kekuatan hukum sehingga aspek
                    legalnya terpenuhi dan bisa beraktivitas sesuai dengan rencana
                    pendiriannya. Pendirian koperasi ini disambut baik dan telah mendapat
                    restu dari Amir Nasional Jemaat Ahmadiyah Indonesia dengan
                    nama koperasi <b>BINA UMATI FADHAL UMAR</b>.
                </p>
                <p>
                    Kesejahteraan antara lain bisa tercapai dengan mendirikan koperasi
                    maka dalam kaitan ini, koperasi hadir dalam mendorong program-program
                    Umur Ammah untuk membangun kesejahteraan bersama anggota
                    Jemaat melalui koperasi yang dikelola oleh para pengurus dengan
                    semangat melayani, mampu bekerja sama, amanah, dan membangun
                    tujuan luhur dalam meningkatkan kesejahteraan anggotanya.
                </p>
                <p>
                    Menyikapi perkembangan terkini, bahkan di era yang semakin banyak
                    ketergantungan dan saling membutuhkan, tak berlebihan kiranya kami
                    mengajak para anggota Jemaat untuk menjadi anggota koperasi.
                    Kami beranggapan Bahwa BERKOPERASi di KALANGAN JEMAAT
                    BERPOTENSI BESAR untuk maju dan terus berkembang. Mari kita
                    dukung kehadirannya dan kita awasi pengelolaannya serta majukan
                    bersama-sama dalam suasana dan semangat persaudaraan sebagai
                    ciri Jamaah islami melalui koperasi.
                </p>
                <br />
                <p class="title">Tujuan</p>
                <p>
                    <ol>
                        <li>Berperan untuk meningkatkan Kesejahteaan anggotanya atas dasar kesepakatan bersama.</li>
                        <li>Mengembangkan kegitan usaha bersama, meningkatkan pendidikan perkoperasian dan dunia usaha. Koperasi jadi tempat bersama menawarkan barang dan jasa dengan harga wajar dan bersaing sehat.</li>
                        <li>Ikut membangun tatanan ekonomi yang konstruktif, antara lain memberikan konstribusi untuk masyarakat di sekitarnya dengan menaikan taraf hidup atas dasar kekeluargaan dan demokratis.</li>
                    </ol>
                </p>
                <br />
                <p class="title">Visi</p>
                <p>
                    Menjadi koperasi yang <b>terpercaya</b>, <b>bermanfaat</b>, <b>sukses</b>, <b>tangguh</b> dan memberikan <b>pelayanan prima</b> kepada anggota koperasi dan masyarakat dalam meningkatkan pendapatan koperasi dan kesejahteraan anggota.
                </p>
                <br />
                <p class="title">Misi</p>
                <p>
                    <ol>
                        <li>Menjalin silaturahmi antara pengurus dan anggota dalam menjalankan koperasi supaya tetap tangguh dan berhasil.</li>
                        <li>Mengusahakan penyediaan kebutuhan pokok anggota dan masyarakat berupa barang-barang primer dan sekunder.</li>
                        <li>Membantu anggota dalam bermitra usaha dan memasarkan barang atau hasil produksi yang berkualitas, dibutuhkan oleh masyarakat dan harganya kompetitif.</li>
                        <li>Meningkatkan pelayanan yang prima kepada anggota koperasi dan pelanggan lainnya.</li>
                        <li>Meningkatkan peran serta anggota koperasi dalam pengembangan koperasi ke arah yang lebih tangguh dan maju dengan prinsip efisien, efektif dan produktif.</li>
                        <li>Mengelola manajemen koperasi dengan profesional.</li>
                        <li>Meningkatkan jejaring kemitraan dengan anggota, koperasi atau pihak lain dalam pembinaan dan pengembangan koperasi di dalam negeri maupun di luar negeri.</li>
                    </ol>
                </p>
                <br />
                <p class="title">Alamat Sekretariat</p>
                <p>Jl. Sunan Gunung Jati No.1, Kel.Paninggilan, Kec.Ciledug Kota Tangerang</p>
            </v-col>
        </v-row>
        </v-container>
      </v-main>
  </div>
  
</template>

<script>
import {contactWA, contactEmail} from '@/lib/util/utilities.js'

export default {
  name: 'GeneralQuestions',
  components: {
  },
  data() {
    return {
      verification: false,
      mobilePhone: this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm',
      showBottomNav: false
    }
  },
  methods:{
    contactWA, contactEmail
  },
  mounted() {
    switch (this.$vuetify.breakpoint.name) {
        case 'xs': 
        this.showBottomNav = true
        break
        case 'sm': 
        this.showBottomNav = true
        break
        case 'md': 
        this.showBottomNav = false
        break
        case 'lg': 
        this.showBottomNav = false
        break
        case 'xl': 
        this.showBottomNav = false
        break
    }
    if(this.$router.currentRoute.hash === '#verification'){
        this.verification = true
        if (this.showBottomNav) {
            window.scrollTo({
            top:855,
            left: 0,
            behavior: 'smooth'
            });   
        }
    }
  },
}
</script>
<style scoped>
p{
    font-size:13px;
    text-align:justify;
    letter-spacing: 1px;
    line-height: 18px;
}

a{
    text-decoration: none;
    font-weight: bold;
}

</style>
